<template>
  <div>
    <header-page :title="'Statuses Groups'" :hasBackLink="false" />

    <div class="filters-container">
      <div class="filters-block">
        <div class="search">
          <input-text v-model="search_text" />
          <div
            v-if="isShowClear"
            class="main-button lg ml-15"
            @click="clearFilters"
          >
            <div>Clear</div>
          </div>
        </div>
        <div class="button">
          <div v-if="false" class="main-button lg orange" @click="setFilters">
            <div class="icon" v-html="Filter"></div>
            <div>Filters</div>
          </div>
        </div>
      </div>
      <div class="selected-filters" v-if="selectedFilters.length">
        <div v-for="item in selectedFilters" :key="item.text">
          <div class="icon" @click="removeFilter(item.id)" v-html="Cross"></div>
          <div class="text">{{ item.text }}</div>
        </div>
      </div>
    </div>

    <grid
      v-model:columns="columns"
      v-model="rows"
      :options="options"
      :page="page"
      :per_page="per_page"
      :newRowComponent="newRowComponent"
      :isHideNewRowComponent="isHideNewRowComponent"
      :countRows="countRows"
      :search_text="search_textToGrid"
      @deleteRow="deleteRow"
      @nextPage="nextPage"
      @prevPage="prevPage"
      @saveNewRow="saveNewEntity"
      @saveRow="saveEntity"
      @setPerPage="setPerPage"
      @sortRows="fetchData"
      @setTab="setTab"
      :listTabs="listTabs"
    />
    <set-filters v-if="isVisibleFilters" v-model="isVisibleFilters" />
  </div>
</template>

<script>
import { shallowRef } from 'vue'
import NewRow from '@/components/NewRows/NewRowLinkStatusGroup'
import { fetchData, urls } from '@/utils/urls.js'
import { hasAccess, permissions } from '@/utils/permissions'
import { listMixin } from '@/utils/list-mixin'
import { GrigOptions } from '@/models/grid-options'
import { GridColumn } from '@/models/grid-column'

export default {
  name: 'LinkStatusesGroups',
  mixins: [listMixin],

  data() {
    let colsKeyUrlIndex = 0
    return {
      options: new GrigOptions({
        isSelectable: false,
        isEditable: hasAccess(permissions.update_link_statuses_group),
        isAdd: hasAccess(permissions.create_link_statuses_group),
        isDelete: hasAccess(permissions.delete_link_statuses_group),
        addButtonText: 'Add group',
        getDeleteContent: async (rows) => {
          if (!rows) return ``
          // let query = `page=1&per_page=all`
          // let entitys = await fetchData(
          //   urls.linkStatusesGroups,
          //   'GET',
          //   null,
          //   query + `&group_f=` + rows[0].group
          // )
          // let count = entitys?.count || 0

          //if (!count) {
          this.options.deleteModalOptions.hideYes = false
          return `<div class="mb-10">Are you sure you want to delete group?</div>`
          // } else {
          //   this.options.deleteModalOptions.hideYes = true
          //   return `<div class="mb-10">
          //     You can’t delete the statuse till it’s assigned to existing links.
          //       ${links.rows.map((item) => `<div>• ${item.url}</div>`).join('')}
          //     </div>`
          // }
        },
      }),

      columns: [
        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'group',
          text: 'Group',
        }),
      ],

      newEntity: {
        group: null,
      },
      newRowComponent: shallowRef(NewRow),

      listTabs: [
        {
          id: 'link-statuses',
          name: 'Link Statuses',
          isActive: false,
          isVisible: true,
        },
        {
          id: 'groups',
          name: 'Groups',
          isActive: true,
          isVisible: hasAccess(permissions.get_link_statuses_groups),
        },
      ],
    }
  },

  created() {
    this.setDataFromQueryParams() // 3
    this.fetchData()
  },

  methods: {
    setTab() {
      this.$router.push('/link-statuses')
    },

    saveNewEntity(entity) {
      this.setNewEntity(entity)
      this.addRow()
    },

    saveEntity(entity) {
      this.editRow(entity)
    },

    newEntityToDefault() {
      for (let key in this.newEntity) {
        this.newEntity[key] = null
      }
    },

    setNewEntity(entity) {
      for (let key in entity) {
        this.newEntity[key] = entity[key]
      }
    },

    async editRow(entity) {
      this.$store.commit('setIsLoading', true)
      const id = entity.id
      delete entity.id

      let result = await fetchData(
        urls.linkStatusesGroupsUpdate + `/${id}`,
        'POST',
        this.bodyToSave(entity)
      )
      if (!result?.detail) {
        let entityRow = this.rows.find((item) => item.id === id)
        for (const key in entity) {
          if (key in entityRow) entityRow[key] = entity[key]
        }

        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async addRow() {
      this.$store.commit('setIsLoading', true)

      let result = await fetchData(
        urls.linkStatusesGroupsCreate,
        'POST',
        this.bodyToSave(this.newEntity)
      )
      if (!result?.detail) {
        const tmpEntity = this.newEntity
        this.rows.unshift({ ...{ id: result.id }, ...tmpEntity })
        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })

        this.newEntityToDefault()
      } else {
        this.isHideNewRowComponent = false
        console.error(result.detail)
      }

      this.$store.commit('setIsLoading', false)
    },

    async deleteRow(id) {
      this.$store.commit('setIsLoading', true)

      let result = await fetchData(
        urls.linkStatusesGroupsDelete + `/${id}`,
        'DELETE'
      )
      if (!result?.detail) {
        const idx = this.rows.findIndex((item) => item.id === id)
        this.rows.splice(idx, 1)
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async fetchData() {
      this.setQueryParams()

      if (this.isLoading) return

      this.$store.commit('setIsLoading', true)
      let query = `page=${this.page}&per_page=${this.per_page}`
      let prevSearch_text = this.search_text.value
      if (this.search_text.value?.length > 2) {
        query += `&search_text=${this.search_text.value}`
      }

      query += this.queryToSort()

      let result = await fetchData(urls.linkStatusesGroups, 'GET', null, query)

      this.rows = (result?.rows || []).map((item) => {
        return {
          id: item.id,
          group: item.group,
        }
      })

      this.countRows = result?.count || 0
      this.search_textToGrid = this.search_text.value
      this.$store.commit('setIsLoading', false)

      if (
        this.search_text.value?.length > 2 &&
        prevSearch_text !== this.search_text.value
      )
        this.fetchData()
    },

    setFilters() {
      this.isVisibleFilters = !this.isVisibleFilters
    },
  },
}
</script>
