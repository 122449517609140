<template>
  <tr class="add-row">
    <td>
      <div class="add-row-buttons">
        <div class="main-button orange no-background sm" @click="save">
          Save
        </div>
        <div class="main-button no-background sm" @click="cancel">Cancel</div>
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('group'))">
        <input-text v-model="group" />
      </div>
    </td>
  </tr>
</template>

<script>
import { newRowMixin } from '@/utils/new-row-mixin'

export default {
  name: 'NewRowLinkStatusGroup',
  mixins: [newRowMixin],

  data() {
    return {
      group: {
        value: '',
        isValid: false,
        clearable: true,
        required: true,
        readonly: false,
        errorKey: '',
        class: 'sm',
      },
    }
  },

  created() {
    for (let key in this.rowData) {
      const element = this.rowData[key]
      if (key in this.$data) {
        this.$data[key].value = element
        if (element || element === 0 || element === false)
          this.$data[key].isValid = true
      }
    }
  },

  computed: {
    isValid() {
      if (!this.group.isValid) return false

      return true
    },
  },

  methods: {
    cancel() {
      this.$emit('cancel')
    },

    save() {
      if (!this.isValid) return

      this.$emit('save', this.rowForSave())
    },
  },
}
</script>
